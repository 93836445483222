<template>
  <div class="container mt-4 mb-3">
    <div class="row" style="justify-content: space-around; align-items: center">
      <div class="col-md-6" v-if="bi.name === ''">
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          Novo BI
        </h1>
        <p>Preencha as informações sobre o novo BI</p>
      </div>
      <div class="col-md-6" v-else>
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          {{ bi.name }}
        </h1>
        <p>Veja informações sobre {{ bi.name }}</p>
      </div>
      <div>
        <img src="/assets/img/profile.png" />
      </div>
    </div>
    <hr class="mt-4" style="background: #a1000c; width: 88%" />
    <div class="container mb-5">
      <div class="row mt-2" style="justify-content: center">
        <div class="col-md-10 mb-3">
          <label for="exampleFormControlInput1" class="form-label">URL</label>
          <b-form-input
            v-model="bi.link"
            type="text"
            placeholder="URL"
            class="input"
          ></b-form-input>
        </div>
      </div>
      <div class="row" style="justify-content: center">
        <div class="col-md-5 mb-3">
          <label for="exampleFormControlInput1" class="form-label">Nome</label>
          <b-form-input
            v-model="bi.name"
            placeholder="Nome"
            class="input"
          ></b-form-input>
        </div>
        <div class="col-md-5">
          <label for="inputState">Setor</label>
          <select
            multiple
            id="inputState"
            v-model="bi.departments"
            class="form-control"
          >
            <option
              :value="setor.id"
              v-for="(setor, i) in departments"
              :key="i"
            >
              {{ setor.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-5" style="justify-content: flex-end">
        <b-button class="mr-2" @click="cancelar">Cancelar</b-button>
        <b-button
          variant="danger"
          class="px-4"
          v-if="$route.params.id !== undefined"
          @click="editBIS"
          >Salvar</b-button
        >
        <b-button variant="danger" class="px-4" v-else @click="createBI"
          >Criar</b-button
        >
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.field-icon
  color: #A9A9A9
  float: right
  margin-left: -25px
  margin-top: -29.5px
  margin-right: 10px
  position: relative
  z-index: 2
  cursor: pointer
</style>

<script>
import AreaService from "../../services/AreaService/AreaService";
import DepartmentService from "../../services/DepartmentService/DepartmentService";

import UserService from "../../services/UserService/UserService";

export default {
  name: "ProfileHead",
  props: ["user", "users"],
  data() {
    return {
      areas: [],
      areaService: null,
      departments: [],
      departmentService: null,

      passwordFieldType: "password",
      bi: [],
    };
  },

  mounted() {
    this.userService = new UserService();
    this.areaService = new AreaService();
    this.getAllAreas();
    this.departmentService = new DepartmentService();
    this.getAllDepartments();
    this.getId();
  },

  methods: {
    async getAllAreas() {
      this.areas = await this.areaService.allAreas();
    },

    async getAllDepartments() {
      this.departments = await this.departmentService.allDepartments();
    },

    createBI() {
      console.log("edfrgh");
      this.areaService.createBI(this.bi);
    },

    async editBIS() {
      await this.areaService.editBI(this.bi);
      return this.$router.push("/bis");
    },
    cancelar() {
      return this.$router.push("/bis");
    },

    async getAllBis() {
      this.bis = await this.areaService.allBis();
    },

    async getId() {
      console.log(this.$route.params.id);
      if (!this.$route.params.id) {
        this.bi = {
          departments: [],
          name: "",
          link: "",
        };
      } else {
        let id = this.$route.params.id;
        this.bi = await this.areaService.getBiId(id);
      }
    },
  },
};
</script>
